.bannerhome {
  background-image: none;
  background-color: #882020;
}

div.mainpage {
  background-color: #771e1e;
  background-image: url(images/hero-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

div.mainpage h1,
div.mainpage h2,
div.mainpage h3,
div.mainpage h4,
div.mainpage h5,
div.mainpage h6 {
  color: white;
}

div.racetimetable {
  color: #ffffff;
}

div.racetimetable div {
  font-size: 1.2em;
}

div.racetimetable div.racetype {
  text-align: right;
}

div.racetimetable div.racetime {
  text-align: left;
}
