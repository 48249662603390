div.Header {
    text-align: center;
    color: var(--nav-text-color);
    min-height: 50px;
    padding: 1em;
}

div.Header p {
    text-align: center;
    color: var(--nav-text-color);
}

div.Header a:hover,
.dropdown:hover .dropbtn {
    color: var(--nav-text-hover-color);
}

div.Header a {
    color: var(--nav-text-color);
    text-align: center;
    text-decoration: none;
    font-size: 1.2em;
}

