div.sponsor-level {
    margin: 25px 0 25px 0;
    padding: 25px 0 25px 0;
    border-radius: var(--normal-border-radius);
    background-color: var(--textbox-background-color);
    border-color: #000000;
}

div.sponsor-level h3 {
   padding: 10px;
}

div.sponsors-thank-you {
    border: #000000 solid 1px;
    background-image:url(images/SponsorsThanks.png);
}

div.sponsors-thank-you p {
    color: white;
}

div.sprinter-sponsor div.sponsorName {
    margin-top: 10px;
}