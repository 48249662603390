:root {
  --main-bg-color: #e4e4e4;
  --dark-bg-color: #882020;
  --nav-text-color: #ffffff;
  --nav-text-hover-color: #BBB;
  --button-bg-color: #e84545;
  --button-text-color: white;
  --button-bg-hover-color: #c75757;
  --button-text-hover-color: #b5b5b5;
  background-color: var(--dark-bg-color);
  --textbox-background-color: #00000020;
  --textbox-highlight-background-color: #00000040;
  --normal-border-radius: 20px;
}

div.content * {
  text-align: center;
  font-family: Raleway, sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: var(--dark-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: Lobster;
  src: url(fonts/Lobster.ttf);
}

@font-face {
  font-family: OpenSans;
  src: url(fonts/OpenSans.ttf);
}

@font-face {
  font-family: Raleway;
  src: url(fonts/Raleway.ttf);
}

.banner {
  background-color: #882020;
  background-image: url(images/hero-bg.jpg);
  background-size: cover;
  background-position: center;
  border: #00000020 solid 1px;
}

.herocontent {
  padding-top: 2em;
}

.content {
  padding: 10px;
}

.green-background {
  background-color: #0c3c0c;
  background-image: url(images/SponsorsThanks.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #601414;
}

p {
  font-family: OpenSans;
}

.lobster {
  font-family: Lobster;
}

.button {
  background-color: var(--button-bg-color);
  color: var(--button-text-color);
  padding: 0.5em 2em;
  text-decoration: none;
  border-radius: var(--normal-border-radius);
}

.button-accent:hover,
.button-accent:focus {
  background: var(--button-bg-hover-color);
  color: var(--button-text-hover-color);
}

.mainbutton {
  margin: 2em;
  margin-bottom: 1em;
  font-size: 1em;
}

.button-caption {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 0;
  font-size: 1.1em;
}


div.textbox {
  background-color: var(--textbox-background-color);
  border-radius: var(--normal-border-radius);
  padding: 10px;
}

div.textbox-highlight {
  border: var(--button-bg-color) dotted 2px;
}

div.textbox-highlight * {
  color: var(--button-bg-color);
}

.photo {
  width: 90%;
  margin: 10px;
  border-radius: var(--normal-border-radius);
}