div.Footer {
    text-align: center;
    color: var(--nav-text-color);
    min-height: 50px;
    padding: 1em;
    font-size: 0.8em;
}

div.Footer p {
    text-align: center;
    color: var(--nav-text-color);
}


div.Footer a:hover,
.dropdown:hover .dropbtn {
    color: var(--nav-text-hover-color);
}

div.Footer a {
    color: var(--nav-text-color);
    text-align: center;
    text-decoration: none;
    font-size: 1em;
}

img.socials {
    max-width: 64px;
}