img.sponsor-logo {
    width: 90%;
    margin: 10px;
    border-radius: var(--normal-border-radius);
}

div.Sponsor a {
    text-decoration: none;
    color: inherit;
}

div.Sponsor a:hover {
    text-decoration: underline;
    color: #00000080;
}

div.Sponsor a img.sponsor-logo:hover {
    border: #000000 solid 1px;
}

div.sprinter-sponsor {
    margin-top: 0;
    padding-top: 0;
}