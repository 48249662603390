div.PageWrapper {
    margin: 0;
    padding: 0;
    background-color: var(--dark-bg-color)
}

div.content {
    margin: 0;
    padding: 20px;
    background-color: var(--main-bg-color);
}
